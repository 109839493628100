<template>
  <div class="btc-video-box">
    <video id="btc-video" height="100%" style="object-fit: contain;max-width: 100%;" controlslist="nodownload">
      <source :src="item.VideoLink || item.NewsContext" type="video/mp4">
    </video>
    <div class="video-control">
      <b-progress :value="value" variant="primary" height="6px"/>
      <div class="video-btn">
        <div>
          <div>
            <img v-if="playpause===1" src="@/assets/image/btc-video/play.png" alt="播放" class="hand btn1" @click="handlePlayPause(1)">
            <img v-if="playpause===2" src="@/assets/image/btc-video/pause.png" alt="暂停" class="hand btn1" @click="handlePlayPause(2)">
            <!-- <video-play-pause @toggle="handleTaggle"/> -->
          </div>
          <div>
            <video-volume :volume.sync="volume" :isEnExpire="isEnExpire" :isTopNew="item.IsTopNews" :is-cn="isCn" @volume="handleVolumeChange"/>
          </div>
          <div>
            <img :src="item.Collection ? starFill : starEmpty" alt="收藏" class="hand btn3" @click="handleCollection()">
          </div>
        </div>
        <div>
          <div style="color: #7095b4;">
            <span style="color: #ffffff;">{{ currentTime }}</span> / <span>{{ duration }}</span>
          </div>
          <img src="@/assets/image/btc-video/full-screen.png" alt="全屏" class="hand btn2" @click="handleFullScreen">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'
import VideoVolume from './components/volume'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'BTCVideo',
  components: {
    VideoVolume
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isPrice: {
      type: Boolean,
      default: false
    },
    isCn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      volume: 20,
      value: 0,
      video: null,
      playpause: 1,
      duration: '',
      currentTime: '00.00',
      starEmpty: require('@/assets/image/btc-video/star-empty.png'),
      starFill: require('@/assets/image/btc-video/star-fill.png'),
      collectQuery: {
        NewsID: 0,
        ReportID: 0,
        UserID: getLocalStore().UserID,
        Type: 'add'
      }
    }
  },
  watch: {
    item (oval, nval) {
      if (oval !== nval) {
        this.playpause = 1
        this.video.load()
      }
    }
  },
  computed: {
    isEnExpire () {
      if (this.isCn) {
        return true
      }
      return this.getUserProfile(this.$t('message._beefeyes'))
    }
  },
  mounted () {
    this.video = document.getElementById('btc-video')
    this.video.oncanplay = () => {
      this.duration = this.getFormatTime(this.video.duration)
    }
    this.video.ontimeupdate = () => {
      this.currentTime = this.getFormatTime(this.video.currentTime)
      this.value = this.video.currentTime / this.video.duration * 100
    }
    this.video.onended = () => {
      this.playpause = 1
      // setTimeout(() => {
      //   this.value = 0
      // }, 1000)
    }
  },
  methods: {
    AddDeleteWebEyes () {
      this.$store.dispatch('AddDeleteWebEyes', this.collectQuery).then(() => {
        console.log(this.item.Collection ? '收藏成功' : '取消收藏成功')
      }).catch((err) => {
        console.error(err)
      })
    },
    handleTaggle (e) {
      console.log(e)
    },
    // 播放/暂停
    handlePlayPause (key) {
      if (this.$store.getters.token) {
        if (this.item.IsTopNews || this.isEnExpire || this.item.IsFree) {
          switch (key) {
            case 1:
              this.playpause = 2
              this.video.play()
              break
            case 2:
              this.playpause = 1
              this.video.pause()
              break
            default:
              break
          }
        } else {
          if (this.isCn) {
            this.openCnMembershipModal()
          } else {
            this.openMembershipModal()
          }
        }
      } else {
        if (this.isCn) {
          this.openCnLoginModal()
        } else {
          this.openLoginModal()
        }
      }
    },
    // 音量
    handleVolumeChange (e) {
      this.video.volume = e / 100
    },
    // 收藏
    handleCollection () {
      if (this.$store.getters.token) {
        if (this.item.IsTopNews || this.isEnExpire || this.item.IsFree) {
          this.item.Collection = !this.item.Collection
          if (this.isPrice) {
            if (this.item.Collection) {
              this.collectQuery.Type = 'add'
              this.collectQuery.ReportID = this.item.ReportID
              this.collectQuery.NewsID = 0
            } else {
              this.collectQuery.Type = 'delete'
              this.collectQuery.ReportID = this.item.ReportID
              this.collectQuery.NewsID = 0
            }
          } else {
            if (this.item.Collection) {
              this.collectQuery.Type = 'add'
              this.collectQuery.ReportID = 0
              this.collectQuery.NewsID = this.item.NewsID
            } else {
              this.collectQuery.Type = 'delete'
              this.collectQuery.ReportID = 0
              this.collectQuery.NewsID = this.item.NewsID
            }
          }
          this.AddDeleteWebEyes()
        } else {
          if (this.isCn) {
            this.openCnMembershipModal()
          } else {
            this.openMembershipModal()
          }
        }
      } else {
        if (this.isCn) {
          this.openCnLoginModal()
        } else {
          this.openLoginModal()
        }
      }
    },
    // 全屏
    handleFullScreen () {
      if (this.$store.getters.token) {
        if (this.item.IsTopNews || this.isEnExpire || this.item.IsFree) {
          this.video.webkitRequestFullScreen()
        } else {
          if (this.isCn) {
            this.openCnMembershipModal()
          } else {
            this.openMembershipModal()
          }
        }
      } else {
        if (this.isCn) {
          this.openCnLoginModal()
        } else {
          this.openLoginModal()
        }
      }
    },
    // 格式化时间
    getFormatTime (time) {
      // var h = parseInt(time / 3600)
      var m = parseInt(time % 3600 / 60)
      var s = parseInt(time % 60)

      // h = h < 10 ? '0' + h : h
      m = m < 10 ? '0' + m : m
      s = s < 10 ? '0' + s : s
      // h + ':' +

      return m + ':' + s
    }
  }
}
</script>

<style scoped>
.btc-video-box {
  min-width: 924px;
  background: #333333;
  width: 924px;
  height: 450px;
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
}

/* 控制区域 */
.video-control {
  position: absolute;
  width: 100%;
  height: 70px;
  background-color: transparent;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 15px;
}

/* 进度条 */
.video-progress {
  height: 20px;
  background-color: hotpink;
}

/* 播放按钮 */
.video-btn {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.video-btn > div:first-child {
  display: flex;
  align-items: center;
}
.video-btn > div:first-child > div:not(:first-child) {
  margin-left: 30px;
}
.video-btn > div:last-child {
  display: flex;
  align-items: center;
}
.video-btn > div:last-child > img {
  margin-left: 30px;
}
.btn1 {
  width: 30px;
  height: 30px;
}
.btn2 {
  width: 25px;
  height: 25px;
}
.btn3 {
  width: 20px;
  height: 20px;
}
</style>
