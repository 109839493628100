<template>
  <div class="btc-video-volume-box" @click.stop>
    <div v-if="isShow" class="volume-progress"
      @mousedown="handleOutterDown"
      @mousemove="handleOutterMove"
      @mouseup="handleOutterUp"
      @mouseleave="handleOutterUp">
      <div ref="outer" class="outer">
        <div class="inner" :style="{ height: `${height}%` }"/>
      </div>
    </div>
     <!-- @mouseenter="handleMouseEnter" -->
    <img src="@/assets/image/btc-video/volume.png" alt="音量" class="hand btn2" @click="handleVolume">
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'
export default {
  mixins: [commonmix],
  name: 'BTCVideoVolume',
  props: {
    volume: {
      type: Number,
      default: 0
    },
    isTopNew: {
      type: Boolean,
      default: false
    },
    isEnExpire: {
      type: Boolean,
      default: false
    },
    isCn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      status: 'up',
      isShow: false
    }
  },
  mounted () {
    document.body.addEventListener('click', () => {
      this.isShow = false
    }, false)
  },
  computed: {
    height: {
      get () {
        return this.volume
      },
      set (val) {
        this.$emit('update:volume', val)
      }
    }
  },
  methods: {
    handleOutterDown () {
      this.status = 'down'
    },
    handleOutterMove (e) {
      if (this.status === 'down') {
        const pos = this.$refs.outer.getBoundingClientRect()
        const offset = 100 - (e.clientY - pos.y)
        this.height = Math.floor(offset > 100 ? 100 : (offset < 0 ? 0 : offset))
        this.$emit('volume', this.height)
      }
    },
    handleOutterUp () {
      this.status = 'up'
    },
    handleMouseEnter () {
      this.isShow = true
    },
    handleMouseLeave () {
      this.isShow = false
    },
    delay (fn, delay) {
      let timer = null
      return function () {
        if (timer) {
          clearTimeout(timer)
        } else {
          timer = setTimeout(fn, delay)
        }
      }
    },
    handleVolume () {
      // Token不存在则登录
      if (this.$store.getters.token) {
        if (this.isTopNew || this.isEnExpire) {
          this.isShow = !this.isShow
        } else {
          if (this.isCn) {
            this.openCnMembershipModal()
          } else {
            this.openMembershipModal()
          }
        }
      } else {
        if (this.isCn) {
          this.openCnLoginModal()
        } else {
          this.openLoginModal()
        }
      }
    }
  }
}
</script>

<style scoped>
.btc-video-volume-box {
  position: relative;
}
.volume-progress {
  width: 30px;
  height: 100px;
  background: #e0e0e0;
  position: absolute;
  top: -105px;
  left: -3px;
  display: flex;
  justify-content: center;
  border-radius: 18px;
  font-size: 12px;
  box-sizing: border-box;
  padding: 10px 0;
}
.volume-progress .outer {
  height: 100%;
  width: 3px;
  background: #ffffff;
  display: flex;
  align-items: flex-end;
}
.volume-progress .inner {
  width: 100%;
  background: #024579;
  position: relative;
  display: flex;
  justify-content: center;
}
.volume-progress .inner::before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #024579;
  position: absolute;
  top: -8px;
  cursor: pointer;
}
.btn2 {
  width: 25px;
  height: 25px;
}
</style>
